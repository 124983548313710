import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fat } from '@fortawesome/pro-thin-svg-icons'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import FadeInWhenVisible from '../components/fadeInWhenVisible'
import Certifications from '../components/certifications'
import CarouselQuote from '../components/carouselQuote'

library.add(fat)

const Quality = ({ data }) => {
  const post = data.markdownRemark

  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}
          type="root"
        />
      </div>

      <section className="bg-white">
        <FadeInWhenVisible>
          <MDBContainer>
            <MDBRow>
              <MDBCol>
                <div
                  className="mt-3 richtext divlink richtext-image"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </FadeInWhenVisible>
      </section>

      <section className="bg-gray-light">
        <FadeInWhenVisible>
          <MDBContainer>
            <MDBRow>
              <MDBCol>
                <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">
                  Our certifications
                </h2>
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-5">
              <MDBCol>
                {post.frontmatter.section.map((certs, index) => (
                  <Certifications
                    key={index}
                    title={certs.title}
                    description={certs.description}
                    linktext={certs.linktext}
                    document={certs.document}
                  />
                ))}
              </MDBCol>{' '}
            </MDBRow>
          </MDBContainer>
        </FadeInWhenVisible>
      </section>


      {post.frontmatter.quote && 
        <CarouselQuote data={post.frontmatter.quote} />
      }

      {post.frontmatter.qlssection.map((qlssections, index) => (
        <section className="bg-light-blue-prods" key={index}>
          <FadeInWhenVisible>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12" className="pr-5 mb-5">
                  <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">
                    {qlssections.title}
                  </h2>
                  <div
                    className="mt-3 font-w-400 text-medium"
                    dangerouslySetInnerHTML={{ __html: qlssections.description, }}
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow className="mb-5">
                <MDBCol md="3" className="d-none d-sm-block d-md-none d-lg-block">
                  <GatsbyImage
                    top
                    image={qlssections.image.childImageSharp.gatsbyImageData}
                    /* className="d-none d-sm-block" */
                    alt={qlssections.alttext}
                  />
                </MDBCol>

                <MDBCol md="9">
                  <MDBRow>
                    {qlssections.qlsfeature.map((qlsfeatures, index) => (
                      <MDBCol md="12" className="pb-5 px-lg-0 item-prods" key={index}>
                        <div className="feature-icon">
                          <FontAwesomeIcon
                            icon={['fat', qlsfeatures.icon]}
                            size="3x"
                          />
                        </div>
                        <h3 className="font-alt font-w-700 mt-2 letter-spacing-1 title-small">
                          {qlsfeatures.title}
                        </h3>
                        <div
                          className="font-w-400 text-medium"
                          dangerouslySetInnerHTML={{ __html: qlsfeatures.description }}
                        />
                      </MDBCol>
                    ))}
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </FadeInWhenVisible>
        </section>
      ))}
    </Layout>
  )
}
export default Quality

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}   
      canonical={location.pathname} 
    />   
  )
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: { name: { eq: "quality" } }) {
      frontmatter {
        meta {
          title
          description
        }
        title
        subtitle
        ctatext
        ctaslug
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        alttext
        section {
          title
          description
          linktext
          document {
            publicURL
          }
        }
        quote {
          quotenumber
          strapline
          author
        }
        qlssection {
          title
          description
          alttext
          image {
            childImageSharp {
              gatsbyImageData(width: 166, height: 166, quality: 90) 
            }
          }
          qlsfeature {
            title
            icon
            description
          }
        }
      }
      html
    }
  }
`